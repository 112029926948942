<template>
    <v-card  height="100%">
        <DlgMessage ref="dlgMsg" @dlgClose="dlgClose"></DlgMessage>
        <DlgCatalogueItemAdd ref="dlgCommon" :visible="visible" @close="dlgCiClose"></DlgCatalogueItemAdd>
        <div class="pa-3">
          <v-toolbar>
            <v-toolbar-title>Catalogue Items</v-toolbar-title>
            
            <v-spacer></v-spacer>
            <v-breadcrumbs :items="items"></v-breadcrumbs>
          </v-toolbar>
        </div>
        <div class="pa-3">
            <v-card>
                <v-form ref="formSearch">
            <v-row class="mx-1">
                <v-col cols="12" md="4">
                    <v-select
                        v-model="form.search_key"
                        :items="searchKeyList"
                        item-text="description"
                        item-value="code"
                        label="Search Key"
                        standard
                        outlined
                        dense
                        :rules="[rules.required]"
                    ></v-select>
                </v-col>
                <v-col cols="12" md="4">
                    <v-text-field
                        v-model="form.search_word"
                        label="Search Word"
                        outlined
                        dense
                        clearable
                        :rules="[rules.required, rules.searchWord]"
                    ></v-text-field>
                </v-col>
                <v-col cols="12" md="3">
                    <v-select
                        v-model="form.data_limit"
                        :items="dataLimitList"
                        item-text="description"
                        item-value="code"
                        label="Data Limit"
                        standard
                        outlined
                        dense
                    ></v-select>
                </v-col>
                <v-col cols="12" md="1">
                    <v-btn icon condensed color="panel1" @click="refreshSearchTable">
                        <v-icon>mdi-magnify</v-icon>
                    </v-btn>
                </v-col>
                
            </v-row>
                </v-form>
            </v-card>
        </div>
<div class="pa-3">
        <div class="text-right pa-2">   
            <v-btn icon dark class="primary mr-2" @click="refreshDataTable"><v-icon>mdi-refresh</v-icon></v-btn>         
            <v-btn icon dark class="success" @click="addRecord"><v-icon>mdi-plus</v-icon></v-btn>
        </div>

        <v-card>
        <v-card-title>
        <v-text-field
            v-model="tblSearch"
            append-icon="mdi-magnify"
            label="Search data below"
            hide-details
            outlined
        ></v-text-field>
        </v-card-title>


        <v-data-table
        item-key="catalogue_item_id"
        :headers="tblHeaders"
        :items="tblBody"
        :search="tblSearch"
        :loading = "loadingFlag"
        loading-text="Loading... Please wait"
        @click:row="openDetail"

        ></v-data-table>
        </v-card>
        </div>

    </v-card>
</template>
<script>
import ApCatalogueService from "@/services/ApCatalogueService"
import DlgCatalogueItemAdd from "@/components/DlgCatalogueItemAdd";
import DlgMessage from "@/components/DlgMessage";
export default {
    components:{DlgCatalogueItemAdd,DlgMessage},
    data() {
        return{
            //breadcrumb
            items: [
              {text:'Catalogue',disabled: false,href:'/customers',},
              {text:'Item',disabled: false,href:'#',},
              {text:'List',disabled: false,href:'#',},
              ],
            tblHeaders:[
                { text:"Code",value: "ci_code"  },
                { text:"Name", value:"ci_name" },
                { text:"Normal Pts", value:"rd_pts_normal" },
                { text:"Special Pts", value:"rd_pts_special" },
                { text:"Type", value:"ci_display_type_description" },
                { text:"Start Date", value:"start_date" },
                { text:"End Date", value:"end_date" },
                { text:"Status", value:"status" },

            ],
            tblBody:[],
            tblSearch:'',
            searchWord:'',
            searchKey:'ci_code',
            searchKeyList:[
                {code:'ci_code',description:'Code'},
                {code:'ci_name',description:'Name'},
            ],
            form:{
                login_id:"",
                search_key:"ci_code",
                search_word:"",
                data_limit:500
            },

            dataLimit:500,
            dataLimitList:[
                {code:500,description:'500 rows'},
                {code:1000,description:'1000 rows'},
                {code:2000,description:'2000 rows'},
                {code:5000,description:'5000 rows'},
            ],
            loadingFlag:false,
            visible:false,
            rules: {
                required: value => !!value || 'Required.',
                email: value => /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(value) || 'Invalid Email address', 
                integer: value => /^[0-9]+$/.test(value) || 'Number only',
                decimal: value => /^\d{0,9}(\.\d{1,2})?$/.test(value) || 'Number with 2 decimal points',
                pinNumber: value => value.length == 6 || 'require 6 digits number',
                searchWord: value => value.length >= 3 || 'require 3 or more letters',
            },

        }
    },
    async created() {
      this.refreshDataTable();
    },
    methods:{
            async refreshDataTable(){
                this.loadingFlag = true
                try{
                    ApCatalogueService.list_catalogue_item(this.form).then((res) => {
                        //console.log(res);
                        this.tblBody = res.data.data;
                        this.loadingFlag = false;
                        this.errorFlag = false;
                    }).catch((e)=>{
                        //console.log(e.response);
                        if(e.response.data.error_message)
                            this.$refs.dlgMsg.showDlgBox(true,'error','Catalogue Item',e.response.data.error_message);
                        this.loadingFlag = false
                        this.errorFlag = true;
                    })
                    ;
                } catch(e) {
                    this.loadingFlag = false
                    console.log(e);
                    if(e)
                        this.$refs.dlgMsg.showDlgBox(true,'error','Catalogue Item','Unknown Error');
                  
                }
            },
            openDetail(value){
                console.log(value)
                this.$router.push({ name: 'ci-details', params: { catalogue_item_id: value.catalogue_item_id } })
                //this.$router.push("/merchants/detail/" + value.merchant_id);
            },

            addRecord(){
                this.visible = true;
            },
            dlgCiClose(){
                this.visible = false;
                this.refreshDataTable();
            },

            async refreshSearchTable(){
                if(this.$refs.formSearch.validate()){
                    this.loadingFlag = true
                    try{
                        ApCatalogueService.search_catalogue_item(this.form).then((res) => {
                            //console.log(res);
                            this.tblBody = res.data.data;
                            this.loadingFlag = false;
                            this.errorFlag = false;
                        }).catch((e)=>{
                            //console.log(e.response);
                            if(e.response.data.error_message)
                                this.$refs.dlgMsg.showDlgBox(true,'error','Catalogue Item',e.response.data.error_message);
                            this.loadingFlag = false
                            this.errorFlag = true;
                        })
                        ;
                    } catch(e) {
                        this.loadingFlag = false
                        if(e)
                            this.$refs.dlgMsg.showDlgBox(true,'error','Catalogue Item','Unknown Error');
                    
                    }

                }
            },    
            dlgClose(){
            },
        

    }

}
</script>