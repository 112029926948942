<template>
    <v-dialog max-width="700px" v-model="show">
        <DlgMessage ref="dlgMsg" @dlgClose="dlgClose"></DlgMessage>
        <v-card>
            <v-toolbar flat dark color="panel1">
                <v-toolbar-title>New Catalogue Item</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-btn icon dark @click="show=false">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-toolbar>
            <div class="pa-5">
                <v-form ref="mainForm">
                <v-row no-gutters>
                    <v-col cols="6" class="pa-3">
                            <v-text-field 
                                label="Code" 
                                outlined
                                dense
                                v-model="form.ci_code"
                                v-if="show" autofocus  
                                :rules="[rules.required]"
                            ></v-text-field>
                            <v-text-field 
                                label="Name" 
                                outlined
                                dense
                                v-model="form.ci_name"  
                                :rules="[rules.required]"
                            ></v-text-field>
                            <v-menu v-model="dpicker.menu1" :close-on-content-click="false" max-width="290">
                                <template v-slot:activator="{ on, attrs }">
                                    <v-text-field
                                        outlined
                                        dense
                                        :value="form.start_date"
                                        label="Start Date"
                                        readonly
                                        v-bind="attrs"
                                        v-on="on"
                                        :rules="[rules.required]"
                                    ></v-text-field>
                                </template>
                                <v-date-picker v-model="form.start_date" @change="dpicker.menu1 = false"></v-date-picker>
                            </v-menu>
                            <v-menu v-model="dpicker.menu2" :close-on-content-click="false" max-width="290">
                                <template v-slot:activator="{ on, attrs }">
                                    <v-text-field
                                        outlined
                                        dense
                                        :value="form.end_date"
                                        label="End Date"
                                        readonly
                                        v-bind="attrs"
                                        v-on="on"
                                        :rules="[rules.required]"
                                    ></v-text-field>
                                </template>
                                <v-date-picker v-model="form.end_date" @change="dpicker.menu2 = false"></v-date-picker>
                            </v-menu>
                            </v-col>
                            <v-col cols="6" class="pa-3">
                            <v-select
                                label="Display Type"
                                outlined
                                dense
                                :items="dataList['displayTypeList']"
                                item-text="description"
                                item-value="code"
                                v-model = "form.ci_display_type_code"
                                :rules="[rules.required]"
                            ></v-select>

                            <v-text-field 
                                label="Normal Redemption Points" 
                                outlined
                                dense
                                v-model="form.rd_pts_normal"  
                                :rules="[rules.integer]"
                            ></v-text-field>
                            <v-text-field 
                                label="Special Redemption Points" 
                                outlined
                                dense
                                v-model="form.rd_pts_special"  
                                :rules="[rules.integer]"
                            ></v-text-field>

                            
                   </v-col>
                </v-row>
                </v-form>
            </div>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="cbtnSave" dark @click="save">
                    <v-icon left dark>mdi-check</v-icon>
                    Submit
                </v-btn>
                <v-btn class="ml-2" color="cbtnRed" dark @click="show=false">
                    <v-icon left dark>mdi-close</v-icon>
                    Close
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>


<script>
import DlgMessage from '@/components/DlgMessage'
import ApCatalogueService from "@/services/ApCatalogueService"
import moment from 'moment'
export default {
    name: "DlgCatalogueAdd",
    props: {
        visible:Boolean,
    },      
    components:{DlgMessage},
    computed: {
      show: {
        get () {
          return this.visible
        },
        set (value) {
           if (!value) {
                this.$emit('close')
           }
        }
      },
      
    },      
    data() {
    return {
        
      message: "",
      customer_id:0,
      form:{
            
            login_id:"",
            sequence:0,
            ci_code:"",
            ci_name:"",
            ci_description:"",
            start_date:"",
            end_date:"",
            rd_pts_normal:"0",
            rd_pts_special:"0",
            ci_display_type_code:"",
            status:"ACTIVE",
            
          },
        dataList:[],
        dpicker:{menu1: false,menu2: false,},
        respCode:0,
        rules: {
            required: value => !!value || 'Required.',
            email: value => /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(value) || 'Invalid Email address', 
            integer: value => /^[0-9]+$/.test(value) || 'Number only',
            decimal: value => /^\d{0,9}(\.\d{1,2})?$/.test(value) || 'Number with 2 decimal points',
            pinNumber: value => value.length == 6 || 'require 6 digits number',
            searchWord: value => value.length >= 3 || 'require 3 or more letters',
        },
    };
  },
  async created(){
    this.form.start_date = moment(String(new Date())).format('YYYY-MM-DD');
    this.form.end_date = moment(String(new Date())).format('YYYY-MM-DD');
    this.getParameter();

  },
  mounted(){
        this.form.start_date = moment(String(new Date())).format('YYYY-MM-DD');
        this.form.end_date = moment(String(new Date())).format('YYYY-MM-DD');

  },
  methods: {
    dlgShow(){
    },
    dlgClose(){
        console.log(this.respCode);
        
        if(this.respCode == 200){
            this.form.c_code = "";
            this.form.c_name = "";
            this.form.sequence = 0;
            this.show = false;
        }
            
    },
    async save(){
        if(this.$refs.mainForm.validate()) {
            try{
                ApCatalogueService.insert_catalogue_item(this.form).then((res) => {
                    //console.log(res);
                    this.respCode = res.status;
                    this.$refs.dlgMsg.showDlgBox(true,'success','New Catalogue','Success.');
                }).catch((e)=>{
                    //console.log(e.response);
                    this.respCode = e.response.status;
                    if(e.response.data.error_message)
                        this.$refs.dlgMsg.showDlgBox(true,'error','New Catalogue',e.response.data.error_message);
                })
                ;
            } catch(e) {
                if(e)
                    this.$refs.dlgMsg.showDlgBox(true,'error','New Catalogue','Unknown Error');
            }            
        }
    },
    async getParameter(){
        try{
                ApCatalogueService.get_ci_parameter(this.form).then((res) => {
                    //console.log(res);
                    this.dataList = res.data;
                    
                }).catch((e)=>{
                    //console.log(e.response);
                    if(e.response.data.error_message)
                        this.$refs.dlgMsg.showDlgBox(true,'error','New Catalogue',e.response.data.error_message);
                })
                ;
            } catch(e) {
                if(e)
                    this.$refs.dlgMsg.showDlgBox(true,'error','New Catalogue','Unknown Error');
            } 

    }

  }
};

</script>
